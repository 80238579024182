// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-mdx": () => import("./../../../src/pages/about-me.mdx" /* webpackChunkName: "component---src-pages-about-me-mdx" */),
  "component---src-pages-biography-mdx": () => import("./../../../src/pages/biography.mdx" /* webpackChunkName: "component---src-pages-biography-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-mdx": () => import("./../../../src/pages/work.mdx" /* webpackChunkName: "component---src-pages-work-mdx" */)
}

